html {
  scroll-behavior: smooth;
}

.text-center {
  text-align: center;
}

// Fixes scrolling when using the header nav buttons by accounting for the 52px header
a.anchor {
  display: block;
  position: relative;
  top: -52px;
  visibility: hidden;
}

a:visited {
  outline: none;
  border: none;
  -moz-outline-style: none;
}

#splash-img {
  background: url('img/mountains.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#hero-text {
  background-color: rgba(1, 1, 1, 0.5);
  padding: 25px;
}

#about-sec {
  .content {
    margin: 2em auto;
  }

  .divider {
    max-width: 13em;
  }

  #avatar {
    height: 18rem;
    width: 18rem;
  }
}

#links-sec {
  padding-top: 2rem;
  color: white;
  background-color: black;

  .content {
    margin-bottom: 0;
  }

  .divider {
    background-color: white;
    max-width: 15em;
    margin: auto;
    margin-bottom: 2rem;
  }

  .col {
    text-align: center;
  }

  a {
    color: #87d6fa;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    &:hover {
      color:#c2eafc;
    }
  }

  .link-text {
    font-size: 1.6rem;
  }
}

#projects-sec {
  .divider {
    background-color: white;
    max-width: 17em;
    margin: auto;
    margin-bottom: 2rem;
  }

  .card {
    cursor: pointer;

    .overlay {
      position: absolute;
      background-color:#000;
      width: 100%;
      height: 100%;
      -o-transition: 0.5s;
      -ms-transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      z-index: 1;
      opacity: 0;
    }

    &:hover > .overlay {
      opacity: 0.1;
  }
  }
}

#footer {
  padding: 2rem 0 1rem 0;
  margin-top: 0;

  .subtitle {
    font-style: italic;
  }
}
